import React, { useState, useContext, useEffect, Fragment } from 'react';
import Modal from '@material-ui/core/Modal'
import { Link } from 'react-router-dom';
import {ReactComponent as Logo} from '../logo.svg'; 
import {ReactComponent as LogoText } from '../../assets/scss/black-dashboard-react/panther/logo-white-text.svg'; 
import TextSpinner from '../spinners/TextSpinner';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Alerta from '../Alerta';
import AuthContext from '../../context/autenticacion/authContext.js'
import Spinner from '../spinners/Spinner' 
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';  
import Loading from '../Modales/Loading'
import LogoCM from '../Logocapblack.png'
import {
    LIMPIAR_ALERTA,
} from '../../types'; 
import { 
    Row,
    Col,
    CardBody,
    CardHeader,
    CardFooter,
  } from "reactstrap"; 

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'hidden',
    height:'100%',
    
    background: '#0000005c',
      
    },
    paper: {
      background: '#001c52',
      border: '2px solid #04a1ff',
      boxShadow: theme.shadows[5],
      
      
    },
  }));
  
const Login = (props) => {  
    document.oncontextmenu = function() {
        return false
     }
    //  function right(e) {
    //     var msg = "¡Hola Panthera aquí no puedes usar Click Derecho!";
    //     if (navigator.appName == 'Netscape' && e.which == 3) {
           
    //        return false;
    //     }
    //     else if (navigator.appName == 'Microsoft Internet Explorer' && event.button==2) {
           
    //     return false;
    //     }
    //         return true;
    //     }
        // document.onmousedown = right;  
    const [ elcargando, elGuardarCargando] = useState(false);
    const [mensajedos, guardarMensaje] = useState(mensaje); 
    const elguardar = ( elcargando ) ? <Spinner/> :
    <Fragment>
      ENTRAR A MI CUENTA
      </Fragment> 
    const classes = useStyles();
    const [open, setOpen] =useState(true);
    const [ cargando, guardarCargando] = useState(false);
      // ahora vamos a extraer los valores de registrar usuario del context
      const authContext = useContext(AuthContext);
      const { lang,mensaje, autenticado, iniciarSesion,userStatusFun, Limpiar, resendCorreo, status, usuario, 
        demo_status,mis_inversiones_activas, mis_inversiones_vendidas , 
        mis_ofertas_oferente ,mis_ofertas_inversor, BtnProtectOff,
                PantherLoad,
                PantherNoLoad,
                PantherLoadAppRecarga,
                PantherNoLoadAppRecarga,
                ultimos_movimientos,
                proyectos_primario_all
    } = authContext;  
        const [supermensaje , guardarSuperMensaje ] = useState('');
        const handleOpen = () => {
            setOpen(true);
        } 
        const handleClose = () => {
            setOpen(true);
            guardarMensaje(null);
        }
        useEffect( () => {
        guardarSuperMensaje(mensaje)
        // guardarCargando(false);
        // elGuardarCargando(false);
        if( usuario && autenticado 
            && status 
              && ultimos_movimientos && proyectos_primario_all
            //  && mis_inversiones_activas
            //   && mis_inversiones_vendidas 
            //   && mis_ofertas_oferente 
            //   && mis_ofertas_inversor
            ) { 

            props.history.push('/cliente');
            PantherNoLoad();   
            PantherNoLoadAppRecarga();
            BtnProtectOff()
            // if(status === 3){
            //     setTimeout( () => {
            //         guardarCargando(false);
            //         elGuardarCargando(false);
                
            //     props.history.push('/cliente');    
                
            //     },500);
            // } 

            //  if(status === 1 || status === 2){
            //     setTimeout( () => {
            //     guardarCargando(false);
            //         elGuardarCargando(false);
            //     props.history.push('/cliente');    
                
            //     },1000);
            // }
        } 

    } , [mensaje, status,usuario,autenticado,ultimos_movimientos,proyectos_primario_all,props.history]
    );
    // estamos creando el state 
     const [usuariologin, guardarUsuario] = useState({
         email:'',
         password: ''
     });  
    /// manejador de evento del simulador
    // const [usuariologin, guardarUsuario] = useState({
    //     email:'usuariodemo@gmail.com',
    //     password: 'Militares8!'
    // });
    // extraer usuario del state
    const {email,password} = usuariologin; 
    const [ mostrarpassword , setMostrarPassword] = useState(false)
    const onChange = e => {
        // voy a colocar guardar usuario en el state
        guardarUsuario({
            ...usuariologin,
            [e.target.name] : e.target.value
        })
    }
    /// cuando el usuario quiere iniciar seseion
    const onSubmit = e => {
        e.preventDefault(); 
        // elGuardarCargando(true)
        window.scrollTo(0,0)
        PantherLoad()
        // validar que no haya campos vacios
        if(email.trim() === '' || password.trim() ==='') {
            return
        } 
        if (password.length < 6) {  
            return
        }
        iniciarSesion({ email,password}); 
        userStatusFun({email,password})
    }
    const onSubmitCorreo = e => { 
          guardarCargando(true); 
        e.preventDefault(); 
        // validar que no haya campos vacios
        if(email.trim() === '' ) {
            return
        } 
        resendCorreo({ email});
    }






    return ( 
    
    // <>  
    // <Loading/>    
    // <div className="form-usuario ">    
    //         <div className="contenedor-form form-fix-login sombra-dark animation-icon-in-cinco">
   
    //             <h1 className="login-font font-fix-register text-panther-jimf d-none ">Entrar a mi cuenta</h1>     

         
    //             { autenticado ? <p className={`text-center  text-black animation-icon-in-dos ${usuario ? 'd-none':'d-flex'}`} > cargando...   </p> : null }
    //             { usuario ? <p className={`text-center  text-black animation-icon-in-dos ${usuario ? 'd-none':'d-flex'}`}> Bienvenido {usuario.name}  </p> : null }

    //             <form onSubmit={onSubmit} className="mb-1 footer-plus"> 
    //                 <div>

    //                    <div className="campo-form-panther-joe mt-1 animation-icon-in-cinco">
                            
    //                         <label htmlFor="email" class="correo-fix">Correo electrónico</label>
    //                         <input
    //                                 type="email"
    //                                 id="email"
    //                                 name="email"
    //                                 placeholder="Mi correo electrónico"
    //                                 onChange={onChange}
    //                                 value={email}
    //                                 className="campo-form-panther"
    //                                 required
    //                                 autocomplete="true"
    //                                 />
    //                     </div>
    //                     <div className="campo-form-panther-joe animation-icon-in-cinco"> 

    //                             <label htmlFor="password" >Contraseña</label>
    //                              {mostrarpassword ?  
    //                             <div className="d-flex align-items-center pantherpassword"> 
    //                             <input
    //                                 type="text"
    //                                 id="password"
    //                                 name="password"
    //                                 placeholder="Mi contraseña                 "
    //                                 onChange={onChange}
    //                                 value={password}
    //                                 // className="input-panther7 "
    //                                 required
    //                                 autocomplete="true"
    //                                 />
    //                                 <i  
    //                                     onClick={ () => ( 
    //                                         setMostrarPassword(false)
    //                                     )}
    //                                 >
    //                                     <VisibilityIcon className="icon-vis-style" />
    //                                 </i> 
    //                             </div> : 
    //                             <div className="d-flex align-items-center pantherpassword"> 
    //                             <input
    //                                 type="password"
    //                                 id="password"
    //                                 name="password"
    //                                 placeholder="Mi contraseña                      "
    //                                 onChange={onChange}
    //                                 value={password}
    //                                 // className="campo-form-panther "
    //                                 required
    //                                 autocomplete="true"
    //                                 />
    //                                 <i 
    //                                     onClick={ () => ( 
    //                                         setMostrarPassword(true)
    //                                     )}
    //                                 >
    //                                 <VisibilityOffIcon className="icon-vis-style"/>
    //                                 </i>
    //                             </div>  
    //                         } 
    //                     </div>
    //                    </div>
    //                     <div >
    //                     { usuario ?   <button disabled type="submit" className="button-jimf">
    //                             {elguardar}
    //                             </button>
    //                              :   <button type="submit" className="button-jimf">
    //                             {elguardar}
    //                             </button>
    //                         }
    //                         <Link to={"/forgot"} className="enlace-cuenta resetpassword mt-30">
    //                             ¿Olvidó su contraseña?
    //                         </Link>
    //                     </div>
    //                 </form>
    //                  <p class="text-grays text-center mb-0">¿Aún no tiene una cuenta?</p>
    //                 <Link to={"/nueva-cuenta"} className="enlace-cuenta mod-registrese">
    //                 <strong>¡</strong>Crear su cuenta ahora <strong>!</strong>
    //                 </Link>
    //                 <p className="welcometext-panther">
    //                 Nuestra plataforma de ofertas fue diseñada para todo tipo de inversor.
    //                 Invierta diversificando sus riesgos de forma simple, accesible y sin inconvenientes, desde <strong>USD $50.</strong>
    //                 <br/>
    //                 Panther7 es una plataforma de vanguardia que proporciona acceso directo a oportunidades de 
    //                 inversiones seguras y respaldadas por activos especialmente seleccionados.
    //                 </p>
    //                 <a href="https://www.panther7.com" >
    //                 <p className="panther-7-text-footer">
    //                     <strong>www.</strong>panther7<strong>.com</strong>
    //                 </p>
    //                 </a>
    //                 </div>
    //                 {demo_status === "active" ? <p className="Hola">PLATAFORMA DEMO</p> :null }
    //                  </div>  
    //                 {supermensaje === "El usuario debe confirmar la dirección de correo electrónico."  ?
    //                                 <Modal                                                                    
    //                                 className={classes.modal}
    //                                 open={open} 
    //                                 mensaje={supermensaje}
    //                                 disableScrollLock={true}
    //                                 closeAfterTransition
    //                                 BackdropComponent={Backdrop}
    //                                 BackdropProps={{
    //                                     timeout: 900,
    //                                 }}
    //                                 >
    //                                 <Fade in={open}
    //                                 timeout={154}
    //                                 onClose={handleClose}>
    //                                     <div className="container">
    //                                 <div className="row">
    //                                 <div className="col-12 d-flex justify-content-center">
    //                                 <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>  
    //                                 <CardHeader className="bg-modal-invertir-send ">
    //                                             <Row>
    //                                             <Col xs="10" sm="10" md="10" lg="10" className="btn-cancel-justify-center">
    //                                             <h3 className="title display-5 text-left my-4">Felicidades, Ya falta poco para termina tu registro</h3>
    //                                             </Col>
    //                                             <Col xs="2" sm="2" md="2" lg="2" className="btn-cancel-justify-center">
    //                                                                     <div className="cancel-box-btn">
    //                                                                         <div className="cancel-btn-panther" onClick={ () => (
    //                                                                         handleClose(),
    //                                                                         Limpiar(),
    //                                                                                 guardarCargando(false),
    //                                                                                 elGuardarCargando(false)
    //                                                                         )}> 
    //                                                                             <i className="tim-icons icon-simple-remove"/>
    //                                                                         </div>
    //                                                                     </div>
    //                                                                 </Col>
    //                                             </Row>
    //                                             </CardHeader>
    //                                             <CardBody>
    //                                                 <Row className="d-flex justify-content-center">
    //                                                     <Col sm="12" md="11" className=" static-height d-flex align-items-center text-center justify-content-center"> 
    //                                                     <h2 className="text-center mb-1">{supermensaje}</h2>
    //                                                         </Col>       
    //                                                         <Col md="12">
    //                                                                 <form onSubmit={onSubmitCorreo}>
    //                                                                 <div className="campo-form mt-3 justify-content-center">
    //                                                                     <label htmlFor="email" className="text-help-send" >Email </label>
    //                                                                                         <input
    //                                                                                             type="email"
    //                                                                                             id="email"
    //                                                                                             name="email"
    //                                                                                             placeholder="Escribe tu correo electrónico"
    //                                                                                             onChange={onChange}
    //                                                                                             value={email}
    //                                                                                             className="campo-form-panther-send"
    //                                                                                             required
    //                                                                                             />
    //                                                                                 </div> 
    //                                                                                 <hr className="bg-info"/>
    //                                                                                 <div className="campo-form "> 
    //                                                                                        <div className="container col-12">
    //                                                                                         <input
    //                                                                                         type="submit" className="btn btn-primario btn-block btn-panther-power"
    //                                                                                         value="Reenviar enlace de confirmación de cuenta"  /> 
    //                                                                                     </div>                                                                                            
    //                                                                                 </div>
    //                                                                             </form>
    //                                                                 </Col>
    //                                                         </Row> 
    //                                                     </CardBody>
    //                                                 </div>
    //                                                 </div>
    //                                             </div>
    //                                             </div>
    //                                 </Fade>
    //                                 </Modal>     :null
    //                                     }
    //                                     {mensaje ?
    //                                         <Modal
                                                                
    //                                                             className={classes.modal}
    //                                                             open={open} 
    //                                                             mensaje={mensaje}
    //                                                             disableScrollLock={true}
                                                                
    //                                                             closeAfterTransition
    //                                                             BackdropComponent={Backdrop}
    //                                                             BackdropProps={{
    //                                                                 timeout: 900,
    //                                                             }}
    //                                                             >
    //                                                             <Fade in={open}
    //                                                             timeout={154}
    //                                                             onClose={handleClose}>
    //                                                                 <div className="container">
    //                                                             <div className="row">
    //                                                             <div className="col-12 d-flex justify-content-center">
    //                                                             <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}> 
                                                               
                                                                    
    //                                                             <CardBody>
    //                                                                 <Row className="d-flex justify-content-center">
    //                                                                                 <Col sm="12" md="11" className=" static-height">                     
    //                                                                                 <hr className="bg-info"/>
    //                                                                                             <h1 className="text-center">{mensaje}</h1>
    //                                                                                             {mensaje === "Intente nuevamente." ? <>
    //                                                                     <span className="intente-nuevamente">Intente nuevamente, Panther7 ha cerrado todas sus sesiones abiertas! Panther7 
    //                                                                         por seguridad solo permite usar su cuenta en un solo equipo simultaneamente, Por favor vuelva a intentarlo! </span>
    //                                                                     </>: null }
    //                                                                             </Col>       
    //                                                                 </Row>
    //                                                             </CardBody> 
    //                                                             <CardFooter>
    //                                                                     <div className="row">
    //                                                                         <div  className="col-12">
    //                                                                         <button className=" btn mt-0 btn-block btn-primario btn-info btn-panther-power" onClick={ ()=>(
    //                                                                                 handleClose(),
    //                                                                                 Limpiar(),
    //                                                                                 guardarCargando(false),
    //                                                                                 elGuardarCargando(false)
    //                                                                         )}>
    //                                                                         Intente nuevamente
    //                                                                         </button>
    //                                                                         </div>
    //                                                                     </div>
    //                                                                 </CardFooter>
    //                                                             </div>
    //                                                             </div>
    //                                                             </div>
    //                                                             </div>
    //                                                             </Fade>
    //                                                             </Modal>            
    //                             : null}
    //                 </>


    <>  
    <Loading/>    
    <div className="form-usuario ">    
            <div className="contenedor-form form-fix-login sombra-dark animation-icon-in-cinco">
   
                <h1 className="login-font font-fix-register text-panther-jimf d-none ">Entrar a mi cuenta</h1>     

         
                { autenticado ? <p className={`text-center  text-black animation-icon-in-dos ${usuario ? 'd-none':'d-flex'}`} > {lang.Login.loading} </p> : null }
                { usuario ? <p className={`text-center  text-black animation-icon-in-dos ${usuario ? 'd-none':'d-flex'}`}> {lang.Login.welcome} {usuario.name}  </p> : null }
                <div className="box-logo-cm">

<img
alt="logo"
src={LogoCM}
className='logo-cm'
/>

</div>
                <form onSubmit={onSubmit} className="mb-1 footer-plus"> 
                    <div>

                       <div className="campo-form-panther-joe mt-1 animation-icon-in-cinco">
                            
                            <label htmlFor="email" class="correo-fix">{lang.Login.emailLabel}</label>
                            <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder={lang.Login.emailPlaceholder}
                                    onChange={onChange}
                                    value={email}
                                    className="campo-form-panther"
                                    required
                                    autocomplete="true"
                                    />
                        </div>
                        <div className="campo-form-panther-joe animation-icon-in-cinco"> 

                                <label htmlFor="password" >{lang.Login.passwordLabel}</label>
                                 {mostrarpassword ?  
                                <div className="d-flex align-items-center pantherpassword"> 
                                <input
                                    type="text"
                                    id="password"
                                    name="password"
                                    placeholder={lang.Login.passwordPlaceholder}
                                    onChange={onChange}
                                    value={password}
                                    // className="input-panther7 "
                                    required
                                    autocomplete="true"
                                    />
                                    <i  
                                        onClick={ () => ( 
                                            setMostrarPassword(false)
                                        )}
                                    >
                                        <VisibilityIcon className="icon-vis-style" />
                                    </i> 
                                </div> : 
                                <div className="d-flex align-items-center pantherpassword"> 
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder={lang.Login.passwordPlaceholder}
                                    onChange={onChange}
                                    value={password}
                                    // className="campo-form-panther "
                                    required
                                    autocomplete="true"
                                    />
                                    <i 
                                        onClick={ () => ( 
                                            setMostrarPassword(true)
                                        )}
                                    >
                                    <VisibilityOffIcon className="icon-vis-style"/>
                                    </i>
                                </div>  
                            } 
                        </div>
                       </div>
                        <div >
                        { usuario ?   <button disabled type="submit" className="button-jimf">
                                {lang.Login.signInButton}
                                </button>
                                 :   <button type="submit" className="button-jimf">
                                {lang.Login.signInButton}
                                </button>
                            }
                            <Link to={"/forgot"} className="enlace-cuenta resetpassword mt-30">
                                {lang.Login.forgotPasswordLink}
                            </Link>
                        </div>
                    </form>
                     <p class="text-grays text-center mb-0">{lang.Login.noAccountMessage}</p>
                    <Link to={"/nueva-cuenta"} className="enlace-cuenta mod-registrese">
                    <strong>{lang.Login.createAccountLink}</strong>
                    </Link>
                    <p className="welcometext-panther">
                    {lang.Login.welcomeMessage}
                    <br/>
                    <a href="https://www.panther7.com" >
                    <p className="panther-7-text-footer">
                        <strong>{lang.Login.websiteLink}</strong>
                    </p>
                    </a>
                    </p>
                    </div>
                    {demo_status === "active" ? <p className="Hola">{lang.Login.demoPlatform}</p> :null }
                     </div>  
                    {supermensaje === lang.Login.confirmationEmailMessage  ?
                                    <Modal                                                                    
                                    className={classes.modal}
                                    open={open} 
                                    mensaje={lang.Login.confirmationEmailMessage}
                                    disableScrollLock={true}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 900,
                                    }}
                                    >
                                    <Fade in={open}
                                    timeout={154}
                                    onClose={handleClose}>
                                        <div className="container">
                                    <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                    <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>  
                                    <CardHeader className="bg-modal-invertir-send ">
                                   
                                                <Row>
                                                <Col xs="10" sm="10" md="10" lg="10" className="btn-cancel-justify-center">
                                                <h3 className="title display-5 text-left my-4">{lang.Login.confirmationEmailTitle}</h3>
                                                </Col>
                                                <Col xs="2" sm="2" md="2" lg="2" className="btn-cancel-justify-center">
                                                                        <div className="cancel-box-btn">
                                                                            <div className="cancel-btn-panther" onClick={ () => (
                                                                            handleClose(),
                                                                            Limpiar(),
                                                                                    guardarCargando(false),
                                                                                    elGuardarCargando(false)
                                                                            )}> 
                                                                                <i className="tim-icons icon-simple-remove"/>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                </Row>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row className="d-flex justify-content-center">
                                                        <Col sm="12" md="11" className=" static-height d-flex align-items-center text-center justify-content-center"> 
                                                        <h2 className="text-center mb-1">{supermensaje}</h2>

                                            
                                                            </Col>       
                                                            <Col md="12">
                                                                    <form onSubmit={onSubmitCorreo}>
                                                                    <div className="campo-form mt-3 justify-content-center">
                                                                        <label htmlFor="email" className="text-help-send" >Email </label>
                                                                                            <input
                                                                                                type="email"
                                                                                                id="email"
                                                                                                name="email"
                                                                                                placeholder={lang.Login.emailPlaceholder}
                                                                                                onChange={onChange}
                                                                                                value={email}
                                                                                                className="campo-form-panther-send"
                                                                                                required
                                                                                                />
                                                                                    </div> 
                                                                                    <hr className="bg-info"/>
                                                                                    <div className="campo-form "> 
                                                                                           <div className="container col-12">
                                                                                            <input
                                                                                            type="submit" className="btn btn-primario btn-block btn-panther-power"
                                                                                            value={lang.Login.resendConfirmationLinkButton}  /> 
                                                                                        </div>                                                                                            
                                                                                    </div>
                                                                                </form>
                                                                    </Col>
                                                            </Row> 
                                                        </CardBody>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                    </Fade>
                                    </Modal>     :null
                                        }
                                        {mensaje ?
                                            <Modal
                                                                
                                                                className={classes.modal}
                                                                open={open} 
                                                                mensaje={mensaje}
                                                                disableScrollLock={true}
                                                                
                                                                closeAfterTransition
                                                                BackdropComponent={Backdrop}
                                                                BackdropProps={{
                                                                    timeout: 900,
                                                                }}
                                                                >
                                                                <Fade in={open}
                                                                timeout={154}
                                                                onClose={handleClose}>
                                                                    <div className="container">
                                                                <div className="row">
                                                                <div className="col-12 d-flex justify-content-center">
                                                                <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}> 
                                                               
                                                                    
                                                                <CardBody>
                                                                    <Row className="d-flex justify-content-center">
                                                                                    <Col sm="12" md="11" className=" static-height">                     
                                                                                    <hr className="bg-info"/>
                                                                                                <h1 className="text-center">{mensaje}</h1>
                                                                                                {mensaje === lang.Login.tryAgainMessage ? <>
                                                                        <span className="intente-nuevamente">{lang.Login.tryAgainMessage}</span>
                                                                        </>: null }
                                                                                </Col>       
                                                                    </Row>
                                                                </CardBody> 
                                                                <CardFooter>
                                                                        <div className="row">
                                                                            <div  className="col-12">
                                                                            <button className=" btn mt-0 btn-block btn-primario btn-info btn-panther-power" onClick={ ()=>(
                                                                                    handleClose(),
                                                                                    Limpiar(),
                                                                                    guardarCargando(false),
                                                                                    elGuardarCargando(false)
                                                                            )}>
                                                                            {lang.Login.tryAgainButton}
                                                                            </button>
                                                                            </div>
                                                                        </div>
                                                                    </CardFooter>
                                                                </div>
                                                                </div>
                                                                </div>
                                                                </div>
                                                                </Fade>
                                                                </Modal>            
                                : null}
                    </>
     );
}
 
export default Login;
