import React, { Fragment, useContext , useEffect, useState, useMemo } from 'react';
import clienteAxios from '../config/axios';

import Spinner from '../components/spinners/Spinner'


import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Container,
  } from "reactstrap"; 

  import styled from 'styled-components'
  import AuthContext from '../context/autenticacion/authContext.js'

  import ModalPanther from '../components/Modales/ModalPanther'

  import Fade from '@material-ui/core/Fade'; 
import CerrarSesion from 'components/Auth/CerrarSesion';

  


// aqui solo aparece el responsive pequenho
  const DivMobile = styled.div `
  padding: 0rem !important;
  margin: 0.2rem 0rem 2.0rem 0rem !important;
  display:flex !important;
  flex-direction:row;
  justify-content:center;
  
  
  
  
  
  @media(min-width:992px) {
    display:none !important;
    justify-content:center;
  }



`;

// pantalla grande
const Divdesktop = styled.div `

  display:none !important;
  
  
  
  
  
  
  @media(min-width:992px) {
    display:block !important;
  }



`; 



  


const UltimosMov = ({ApiLoad,apiload, lang}) => { 


  
const [opendireccion, setOpenDireccion] =useState(false);



const handleOpenDireccion = () => {
    setOpenDireccion(true);
} 

const handleCloseDireccion = () => {
    setOpenDireccion(false);;
}


  const authContext = useContext(AuthContext);
  const { 
    ultimos_movimientos_page,
    ultimos_movimientos_total_page,
    ultimos_movimientos_pdf,
    ultimos_movimientos_csv,
    ultimos_movimientos_cantidad,
    ultimos_movimientos,
    CerrarSesion,
   } = authContext;  

  const [ cargando, guardarCargando] = useState(false);

  


const [elcargandoprimera, elGuardarCargandoPrimera] = useState(false);


const [ elcargandosiguiente, elGuardarCargandoDespues] = useState(false);
 const [ elcargandoantes, elGuardarCargandoAntes] = useState(false);

 const elguardarsiguiente = ( elcargandosiguiente ) ? <Spinner/> :  
 <Fragment>
   Siguiente &raquo;
   </Fragment> 


 const elguardarantes = ( elcargandoantes ) ? <Spinner/> :  

 <Fragment>
   Anterior &laquo; 
   </Fragment> 


const elguardarprimera = ( elcargandoprimera ) ? <Spinner/> :  

<Fragment>
Primera Página
  </Fragment> 




//// aqui vamos a hacer el paginador 
const [ totalpaginas, guardarTotalPaginas ] = useState();

/// aqui estamos colocando el contador de pagina, numero
const [ paginaactualcount, guardarPaginaActualCount ] = useState(paginaactualcount);
// aqui estamos asignando el contador numero a la variable,mediante una cadena de  texto
const [ paginaactual, guardarPaginaActual ] = useState({
  
  page: paginaactual,

});  




  const [ pdf, guardarDocumentacionPdf ] = useState({
    
    pdf:'',

  })
  const [ csv, guardarDocumentacionCsv ] = useState({
    
    csv:'',

  })

    


    const [ balance, guardarBalance ] = useState([{ 
        id: '',
        active: '',
        client_id: '',
        currency_tag: '',
        balance: '',
        log: '',
        created_at: '',
        updated_at: '',
        status: '',
        description: '',
        notes: '',
        type: '',
        updated_by:'',
        code: '',
        saldo:'',
        csv:'',
        pdf:'',
        
    }]); 


    
  const [activardata, guardarActivarData] = useState(true);
  const [cantidad, guardarCantidades ] = useState({});
  

    useEffect( (props) => {
      
      guardarActivarData(true);

        const consultarApiBalance = async () => {
            guardarCargando(true); 

                  
              guardarPaginaActual({
                page:  paginaactualcount

            }) 


            try {
              let respuesta = await clienteAxios.post("/api/client/balance/list",paginaactual)
              
                    
            guardarBalance(respuesta.data.data); 


            
            guardarCantidades(parseInt(respuesta.data.cantidad))
         
            guardarPaginaActualCount(respuesta.data.page)
            guardarTotalPaginas(respuesta.data.total_pages)
            
            //////////
            
            

              
              elGuardarCargandoAntes(false);
              elGuardarCargandoDespues(false);
              elGuardarCargandoPrimera(false);

              guardarDocumentacionPdf(ultimos_movimientos_pdf);
              guardarDocumentacionCsv(ultimos_movimientos_csv);
             
                    
             
              
          } catch (error) { 
              
             
              CerrarSesion()
              
          }


            // const elementosPorPagina = 10;
             


            //////logica con el use reducer

            // guardarBalance(ultimos_movimientos); 
            // guardarCantidades(ultimos_movimientos_cantidad)
         
            // guardarPaginaActualCount(ultimos_movimientos_page)
            // guardarTotalPaginas(ultimos_movimientos_total_page)

            /////////////////
            
              
              
            
          } 




          // const consultarApiBalance = async () => {
          //   guardarCargando(true); 

                  
          //     guardarPaginaActual({
          //       page:  paginaactualcount

          //   })


          //   const elementosPorPagina = 10;
          //   let respuesta = await clienteAxios.post("/api/client/balance/list",paginaactual)

          //   guardarCantidades(parseInt(respuesta.data.cantidad))
         
          //   guardarPaginaActualCount(respuesta.data.page)
          //   guardarTotalPaginas(respuesta.data.total_pages)
            

   
            
            

          //     guardarBalance(respuesta.data.data); 
          //     elGuardarCargandoAntes(false);
          //     elGuardarCargandoDespues(false);
          //     elGuardarCargandoPrimera(false);

     
               
          
          //     guardarDocumentacionPdf(respuesta.data.pdf);
          //     guardarDocumentacionCsv(respuesta.data.csv);
             
              
              
            
            



          //     return
            
          // } 







          consultarApiBalance();
          
          



    }, [!apiload, ultimos_movimientos] );  

    

const Back = e => {
  guardarPaginaActualCount( parseInt(paginaactual.page) - 1  ) 
}  

const Next = e => {  

  guardarPaginaActualCount( parseInt(paginaactual.page) + 1 )

} 

/////// aqui estan los efectos de la paginacion
///////// aqui vemos la parte de llamar a la api
useEffect( (props) => { 

 
  consultarApiPage ({
    ...paginaactual
    
  
  });
    
      },[paginaactual]);
  
    
/////// aqui estan los efectos de la paginacion la parte del guardado
  
  useEffect( (props) => { 
    guardarPaginaActual({
      page:  paginaactualcount,
     })
      },[paginaactualcount]); 


      const consultarApiPage = async (props) => {  

  
        try { 
       
        
       
          const respuesta = await clienteAxios.post('/api/client/balance/list', paginaactual);
         
         guardarCargando(false)
       
         // guardarPaginaActualCount(respuesta.data.page)
       
         
         guardarBalance(respuesta.data.data); 
         guardarPaginaActualCount(respuesta.data.page)
       
         ///// total de paginas
         guardarTotalPaginas(respuesta.data.total_pages) 
       
         guardarCantidades(respuesta.data.cantidad)
         
         // guardarCantidades(parseInt(respuesta.data.cantidad))
       
       
        
       } catch (error) {
        
        CerrarSesion()
                
       }
       }  
       
  

       /////////vamos a hacer el proceso verdaderamente, bien! para generar los pdf /////////

       const [ exportar, generarExporta ] = useState('')









    return (  
        

      <>



      <div 
        
        timeout={1200}
        >  


        {cantidad > 0 ?


        <>
        <Divdesktop>
        <Card className="height-fix-ultimos-movimientos justify-content-center ">
                                                
      <div>
      <CardHeader>
            <CardTitle tag="h2"className="card-title-fix" >
              
              {/* Últimos Movimientos  
               */}

               {lang.UltimosMov.camp01}
              </CardTitle>
            <p><small className="fix-mov-title">  {cantidad} 
            {/* movimientos en la cartera CapitalMarket hasta la fecha */}
            {lang.UltimosMov.camp02}
            </small> </p>
            <div className="export-style">

<div>
{/* Exporte todos tus movimientos en un sólo Clic */}
{lang.UltimosMov.camp03}

</div>

<button  className=" table-export-container-fix text-white" onClick={handleOpenDireccion}>
  <i class="tim-icons icon-badge p-2"/>
    {/* EXPORTAR MOVIMIENTOS  */}
    {lang.UltimosMov.camp04}
  </button>

</div>

          </CardHeader> 

          <CardBody className="header-inversiones-grid movimientos-box-grid" >
                                                                                            
                <div className="grid-container-tabla-movimientos">
                                            
                                                                                                                                          
                                                                                                                                                
                                                                                                                                       
                                                                                                                                              <div className="" data-tooltip="Fecha del movimiento"> 
                                                                                                                                              
                                                                                                                                              <strong className="line-fix">
                                                                                                                                              <i class="tim-icons icon-calendar-60 p-2"/> 
                                                                                                                                              {/* FECHA */}
                                                                                                                                              {lang.UltimosMov.camp05}
                                                                                                                                              </strong>
                                                                                                                                              
                                                                                                                                              </div>
                                                                                                                                              <div className="" data-tooltip="Número de referencia"> 
                                            
                                                                                                                                              <strong className="line-fix">
                                                                                                                                              <i class="tim-icons icon-puzzle-10 p-2"/> 
                                                                                                                                              {lang.UltimosMov.camp06}
                                                                                                                                              {/* REFERENCIA */}
                                                                                                                                              </strong>
                                                                                                                                              
                                                                                                                                              </div>
                                                                                                                                              <div className="" data-tooltip="Detalle del movimiento">
                                            
                                                                                                                                              <strong className="line-fix">
                                                                                                                                              <i class="tim-icons icon-paper p-2"/>  
                                                                                                                                              {lang.UltimosMov.camp07}
                                                                                                                                              {/* DESCRIPCIÓN */}
                                                                                                                                              </strong>
                                                                                                                                              
                                                                                                                                              </div>
                                                                                                                                              <div className="" data-tooltip="Monto del movimiento"> 
                                                                                                                                              <strong className="line-fix text-right align-items-flex-end">
                                                                                                                                              <i class="tim-icons icon-coins p-2"/>  
                                                                                                                                              {lang.UltimosMov.camp08}
                                                                                                                                              {/* MONTO */}
                                                                                                                                              </strong>
                                                                                                                                              
                                                                                                                                              </div>
                                                                                                                                              <div className="" data-tooltip="Saldo total">
                                            
                                                                                                                                              <strong className="line-fix text-right align-items-flex-end">
                                                                                                                                              <i class="tim-icons icon-bank p-2"/> 
                                                                                                                                              {/* SALDO TOTAL */}
                                                                                                                                              {lang.UltimosMov.camp09}
                                                                                                                                              </strong>
                                                                                                                                              
                                                                                                                                              </div> 
                                                                                                                        
                                                                                                                                        </div>
                                            
                                                                                                                                        
                                                                                        </CardBody> 

                                                                                        {balance.map(balances => (
                                                                                              <>
                                                          

                                            <CardBody className="header-inversiones-grid movimientos-box-grid-body animation-cuentas" key={balances.id} >
                                                                                            
                                                <div className="grid-container-tabla-movimientos animation-op ">

                                                                                              
                                                                                                    
                                                                                            <div className="" >
                                                                                                  <strong className="line-fix ">
                                                                                                  {balances.fecha_registro}
                                                                                                  </strong>
                                                                                                  </div>
                                                                                                  <div className="" > 
                                                                                                  
                                                                                                  <strong className="line-fix">
                                                                                                  {balances.code === null ? `000000` : `${balances.code}` }
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className=""> 

                                                                                                  <strong className="line-fix text-left">
                                                                                                  {balances.description}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className={`
                                                                                                                    ${balances.balance < "0" ? 'saldos-neg' : 'saldos-plus' }
                                                                                                                    `} >

                                                                                                  <strong className="line-fix text-right align-items-flex-end">
                                                                                                  {balances.balance}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className="" > 
                                                                                                  <strong className="line-fix text-right align-items-flex-end">
                                                                                                  {balances.saldo}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                
                                                                            
                                                                                            </div>

                                                                                            
                                            </CardBody> 

                                          </>
                                                                                  ) )}
         
          <CardFooter>
                                                      <div className="grid-container-paginador"> 

                                                      

                                                          {paginaactualcount === 1 ?

                                                                    <button
                                                                    title=""
                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                    onClick={Back}
                                                                    disabled
                                                                    > &laquo; 
                                                                    {/* Anterior */}
                                                                    {lang.UltimosMov.camp10}
                                                                     </button>
                                                                    : 

                                                                    <button
                                                                        title=""
                                                                        className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                        onClick={Back}
                                                                      >  &laquo; 
                                                                      {/* Anterior */}
                                                                      {lang.UltimosMov.camp10}
                                                                       </button>
                                                                        }
                                                                      
                                                                      <div className="pagination-style">
                                                                        {/* Página  */}
                                                                        {lang.UltimosMov.camp12}
                                                                        <strong>{paginaactualcount}</strong>  de <span>{totalpaginas}</span> 
                                                                      </div> 


                                                                      {paginaactualcount === totalpaginas ?

                                                                                <button
                                                                                title=""
                                                                                disabled
                                                                                className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                onClick={Next}
                                                                                
                                                                                > 
                                                                                
                                                                                {/* Siguiente  */}
                                                                                {lang.UltimosMov.camp11}
                                                                                &raquo; </button>
                                                                            : 

                                                                                <button
                                                                                    title=""
                                                                                    
                                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                    onClick={Next}
                                                                                    
                                                                                  > 
                                                                                  {/* Siguiente */}
                                                                                  {lang.UltimosMov.camp11}
                                                                                   &raquo; </button>
                                                                                }

                                                      </div>
                                                      

                                                    </CardFooter>

        </div> 
       
        </Card>
      </Divdesktop> 

      <DivMobile> 



        

      <Card className="height-fix-ultimos-movimientos justify-content-center table-responsive">
                                                
                                                <div>
                                                

                                                    <div className="box-grid-header"> 
                                                                <div className="elemento1-mov-header">
                                                                  <h2 className="w-100 text-center">
                                                                    {/* Ùltimos movimientos */}
                                                                    {lang.UltimosMov.camp01}
                                                                    </h2>
                                                                  <p>{cantidad} 
                                                                  {/* movimientos en la cartera hasta la fecha */}
                                                                  {lang.UltimosMov.camp02}
                                                                  </p>
                                                                </div>

                                                                <div className="elemento2-mov-header">
                                                                  <p className="w-100 text-center">
                                                                    {/* Exporte todos tus movimientos en un sólo Clic */}
                                                                    {lang.UltimosMov.camp03}
                                                                  </p>
                                                                  <div  className=" table-export-container-fix text-white" onClick={handleOpenDireccion}>
                                                                      <i class="tim-icons icon-badge p-2"/>
                                                                        {/* EXPORTAR MOVIMIENTOS  */}
                                                                        {lang.UltimosMov.camp04}
                                                                  </div>
                                                                </div>              
                                                    </div>


                                                    <div className=" box-grid"> 
                                                    <div className=" box-grid-son">
                                                    
                                                    {balance.map(balances => ( 


                                                          <div className="ultimos-grid-movimientos animation-cuentas" key={balances.id}>

                                                          <div className="elemento1-mov">
                                                                                    <div>

                                                                                    <i class="tim-icons icon-calendar-60 p-2"/>

                                                                                      <p > <strong> 
                                                                                         {/* Fecha: */}
                                                                                         {lang.UltimosMov.camp05}
                                                                                         </strong> <br/>
                                                                                        {balances.fecha_registro}</p> 

                                                                                    </div>

                                                                                    <div>

                                                                                    <i class="tim-icons icon-puzzle-10 p-2"/>

                                                                                      <p > <strong> 
                                                                                         {/* Referencia: */}
                                                                                         {lang.UltimosMov.camp06}
                                                                                        </strong><br/> 
                                                                                      {balances.code === null ? `000000` : `${balances.code}` } </p>
                                                                                      
                                                                                    </div>
                                                          </div>

                                                          <div className="elemento2-mov"> 
                                                                                      <div> 
                                                                                          
                                                                                      <i class="tim-icons icon-coins p-2"/>
                                                                                          <p >
                                                                                          <strong className="line-fix"> 
                                                                                          
                                                                                          {/* Monto: */}
                                                                                          {lang.UltimosMov.camp08}
                                                                                          
                                                                                          </strong> <br/> <span className={`
                                                                                                                    ${balances.balance < "0" ? 'saldos-neg' : 'saldos-plus' }
                                                                                                                    `} > {balances.balance}</span> </p>

                                                                                      </div> 

                                                                                      <div> 
                                                                                      <i class="tim-icons icon-bank p-2"/>

                                                                                      <p ><strong> 
                                                                                        {/* Saldo Total: */}
                                                                                        {lang.UltimosMov.camp09}
                                                                                        </strong> <br/>   {balances.saldo}</p>

                                                                                      </div>
                                                          </div>

                                                          <div className="elemento3-mov">
                                                            <div>
                                                            <i class="tim-icons icon-badge p-2"/>
                                                              <p ><strong> 
                                                                  {/* Descripcion del movimiento: */}
                                                                  {lang.UltimosMov.camp07}
                                                                </strong><br/> {balances.description}</p>
                                                            </div>
                                                          </div>

                                                          </div> 


                                                                ) )}
                                                        </div>
                                                    </div>



                                                    {/* <CardBody>



                                                                              


                                                          <Container> 

                                                            <Row> 

                                                              




                                                                {balance.map(balances => (
                                                                  <>
                                                              <Col xs="12" sm="6" md="4" key={balances.id} >
                                                                <Card className="card-responsive-bg"> 
                                                                  <CardHeader>
                                                                  <Row>
                                                                  <Col xs="6" sm="6" md="6">
                                                                      <p className="card-responsive-01"> <strong>  Fecha:</strong> <br/>
                                                                        {balances.fecha_registro}</p> 
                                                                        </Col>
                                                                        <Col xs="6" sm="6" md="6">
                                                                        <p className="card-responsive-02"> <strong>  Referencia:</strong><br/> 
                                                                        {balances.code}</p>
                                                                        </Col>
                                                                        </Row>
                                                                  </CardHeader>

                                                                  <CardBody className="card-responsive-07">
                                                                      <Row>
                                                                          <Col xs="6" sm="6" md="6">
                                                                              <p className="card-responsive-03"><strong className="line-fix">  <i class="tim-icons icon-bank pr-1"/> Monto:</strong> <br/> {balances.balance}</p>
                                                                          </Col>

                                                                          <Col xs="6" sm="6" md="6">
                                                                          <p className="card-responsive-04"><strong> Saldo Total:</strong> <br/> {balances.saldo}</p>
                                                                          </Col>

                                                                      </Row>


                                                                  </CardBody>


                                                                  <CardFooter>
                                                                      <p className="card-responsive-05"><strong>  <i class="tim-icons icon-badge p-2"/> Descripcion del movimiento:</strong><br/> {balances.description}</p>
                                                                  </CardFooter>
                                                           
                                                       
                                                                     
                                                                        
                                                                      
                                                                        
                                                   
                                                                </Card>
                                                              </Col>
                                                          </>


                                                                  ) )}
                                          
                                                            </Row>
                                                          </Container>
                                                    </CardBody> */}


                                                    <CardFooter>
                                                      <div className="grid-container-paginador"> 

                                                      

                                                          {paginaactualcount === 1 ?

                                                                    <button
                                                                    title=""
                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                    onClick={Back}
                                                                    disabled
                                                                    > {lang.UltimosMov.camp10} &laquo; </button>
                                                                    : 

                                                                    <button
                                                                        title=""
                                                                        className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                        onClick={Back}
                                                                      > {lang.UltimosMov.camp10}&laquo; </button>
                                                                        }
                                                                      
                                                                      <div className="pagination-style">
                                                                      {lang.UltimosMov.camp12} <strong>{paginaactualcount}</strong>  de <span>{totalpaginas}</span> 
                                                                      </div> 


                                                                      {paginaactualcount === totalpaginas ?

                                                                                <button
                                                                                title=""
                                                                                disabled
                                                                                className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                onClick={Next}
                                                                                
                                                                                > {lang.UltimosMov.camp11} &raquo; </button>
                                                                            : 

                                                                                <button
                                                                                    title=""
                                                                                    
                                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                    onClick={Next}
                                                                                    
                                                                                  > {lang.UltimosMov.camp11} &raquo; </button>
                                                                                }

                                                      </div>
                                                      

                                                    </CardFooter>
                                          
                                          
                                          
                                                  </div> 
                                                 
                                                  </Card>



      </DivMobile>



      
      
      </> : 
      
       <div  className="height-fix-productos-inversion">
              <Card >
              <div>
                 
             
                <CardBody className="d-flex justify-content-center" > 
                
              <Row className="box-depositos-seleccion-alerta justify-content-center col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "> 
            <Col md="12" className=" panther-open-titulo-alerta border-panther"> 
    
            
                        {cantidad === 0 ?  <> 
                        
                                <i className="tim-icons  icon-spaceship animation-icon-in mb-1"/>
                                        <h2 className="animation-icon-in-dos mb-4">
                                          {lang.UltimosMov.camp13}
                                          {/* Sin Movimientos */}
                                          </h2>
                                          <h3 class="animate-raya-03 mb-2">
                                            {/* Aún no tiene movimientos registrados en CapitalMarket */}
                                            {lang.UltimosMov.camp14}
                                            </h3>
                                          <p className="text-center animation-icon-in-cuatro ">
                                            {/* CapitalMarket | Movimientos registrados */}
                                            {lang.UltimosMov.camp15}
                                           </p>
                                          
                                            </> :
                                          <Spinner/>
                                          
                                          }
                          </Col>
                                      
                          </Row>  
                                                             
                  </CardBody> 

                                  
                     </div> 



              </Card>
            </div>   
      
      
      
      
      
      
                          
                          
                          
                          }


      </div> 
      <ModalPanther

          opendireccion={opendireccion}
          handleCloseDireccion={handleCloseDireccion}
          handleOpenDireccion={handleOpenDireccion}
          paginaactualcount={paginaactualcount}
          pdf={pdf}

      
      />

      


                          </>
      
    
    );
}
 
export default UltimosMov;