import React, { useState, useContext, useEffect, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {ReactComponent as Logo} from '../logo.svg';

import { createBrowserHistory } from "history";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import AuthContext from '../../context/autenticacion/authContext.js';
import Alerta from '../Alerta';


import Spinner from '../spinners/Spinner'


import ModalMensaje from '@material-ui/core/Modal'
//// vamos a importar el modal


// importacion del modal
import Modal from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'; 



///// aqui vammos a importar el style component

import styled from 'styled-components'
 

/// aqui vamos a importar como el modal entra 
/// fade del modal
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade'; 

/// aqui vammos a importar el proptypes
import PropType from  'prop-types'; 



/// aqui vamos a importar los estylos del input de material ui

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
/// aqui vamos a colocar la informacion del check y los estilos
import LogoCM from '../Logocapblack.png'
import { 
    Row,
    Col,
    CardBody,
    CardHeader,
    CardFooter,
  } from "reactstrap"; 





function getModalStyle() {
    const top = 50 ;
    const left = 50;
  
    return {
      top: ``,
      left: ``,
      transform: ``,
      
      
    };
  }
  
  
  const useStyles = makeStyles((theme) => ({
        modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'hidden',
    height:'100%',
    
    background: '#0000005c',
      
    },
    paper: {
      background: '#001c52',
      border: '2px solid #04a1ff',
      boxShadow: theme.shadows[5],
      
      
    },
  }));
  
  
  
   


const NuevaCuenta = (props) => { 

    document.oncontextmenu = function() {
        return false
     }

     function right(e) {
        var msg = "¡Hola Panthera aquí no puedes usar Click Derecho!";
        if (navigator.appName == 'Netscape' && e.which == 3) {
            // - Si no quieres asustar a tu usuario entonces quita esta linea...
            
           return false;
        }
        else if (navigator.appName == 'Microsoft Internet Explorer' && e.button==2) {
        return false;
        }
            return true;
        }; 

        document.onmousedown = right;  
    

    const authContext = useContext(AuthContext);
    const { lang, mensaje, autenticado, registrarUsuario, userStatus, Limpiar , LimpiarMensaje, demo_status } = authContext;

    
 const [openmensaje, setOpenMensaje] = useState(true);

 

 const handleOpenMensaje = () => {
     setOpenMensaje(true);
 } 

 const handleCloseMensaje = () => {
     setOpenMensaje(true);
     guardarMensaje(null);
 }


const [registromensaje, guardarRegistroMensaje ] = useState('');

    const hist = createBrowserHistory();

    const [mensajedos, guardarMensaje] = useState(''); 

    const [ elcargando, elGuardarCargando] = useState(false);

    const elguardar = ( elcargando ) ? <Spinner/> :  
                                            
    <Fragment>
      ¡Abrir mi cuenta!
      </Fragment> 
  
  

 // aqui vamos a importar los estados del modal
 const [ modalStyle ] =useState(getModalStyle);
 const [open, setOpen] =useState(false);

 

 const handleOpen = () => {
     setOpen(true);
 } 

 const handleClose = () => {
     setOpen(false); 
     guardarMensaje(null);
 } 


///// esto es el modal de mensaje


 
//// aqui vamos a colocar los estados 

const classes = useStyles();
  const [state, setState] = React.useState({
    
    terminos: false,
    
  });

  

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const {  terminos } = state;
  const error = [terminos].filter((v) => v).length !== 2;




    //extraer los valoresa del context  para la alerta



    // ahora vamos a extraer los valores de registrar usuario del context

    

    //// aqui vamos a hacer un redirect con una opcion true o false

    const [ registrarseclick, setRegistrarseClick ] = useState(false);




    // en caso de que el usuario se haya autenticado 
    // despues del rendering vamos a autenticar el usuario y pasarle las propiedades con los props
    useEffect( () => {

        // if(autenticado) {
        //     props.history.push('/cliente');
        // } 

        // if(autenticado) {
        //     userStatus({ email,password});
        // } 

        // if(registrarseclick) {
           
      
        //         props.history.push('/');

           
        // } 

        if (mensaje) {
            guardarRegistroMensaje(mensaje)
            elGuardarCargando(false);
        }


    } , [mensaje,autenticado,registrarseclick, props.history]
    );
 
    // estamos creando el state
    const [usuario, guardarUsuario] = useState({
        name:'',
        email:'',
        password: '',
        confirmar:'',
        referido:'',
        origen:'',
    });

    // extraer usuario del state

    const {name,email,password,confirmar,referido, origen} = usuario;

    


    const onChange = e => {

        // voy a colocar guardar usuario en el state

        guardarUsuario({
            ...usuario,
            [e.target.name] : e.target.value

        })
    }

    const [ mostrarpassword , setMostrarPassword] = useState(false)


    /// cuando el usuario quiere iniciar seseion
    const onSubmit = e => {
        elGuardarCargando(true)
        e.preventDefault();

     

        //     if( name.trim() === '' || 
        //         email.trim() === '' || 
        //         password.trim() === '' || 
        //         confirmar.trim() === ''  ) {
        //             {mensaje}
        //         } 

        // if (name.trim() === '') {
        //     mostrarAlerta('Coloca tu nombre', 'alerta-panther');
        // }
        
        // // vamos a poner que el password minimo debe tener 6 caracteres
        // if (password.length < 6) {
        //     mostrarAlerta('El Password debe ser de al menos 6 caracteres', 'alerta-panther');
        // }

        // // los 2 password tienen que ser iguales
        // if (password !== confirmar) {
        //     mostrarAlerta('Los password no son iguales','alerta-panther');
        // }




        // luego lo vamos a pasar al action



        // setTimeout( () => {
              
        //     setRegistrarseClick(true)
            
        //     },10000)
  

        if (terminos === true) {
            

            registrarUsuario({
                referido,
                name,
                email,
                password,
                referido,
                origen
            }); 

           

            
        } 
        
       
        
        
        

    }



    return ( 

        <Fade in={open}
        onClose={handleClose}
        timeout={1000}
        
        
        >  
        <Fragment>
        <div className="form-usuario">
            
            <div className="contenedor-form  sombra-dark ">

            <div className="box-logo-cm">

<img
alt="logo"
src={LogoCM}
className='logo-cm'
/>

</div>
                <h1 className="login-font font-fix-register animation-icon-in-dos">¡Crear mi cuenta!</h1>
                
                
                    <form onSubmit={onSubmit} className="mb-4"> 

                    {/* <h1 className="beta-text mb-0">Beta</h1>
                        <p className="text-dark text-center">Bienvenido a la version Beta de <strong> www.Panther7.com</strong></p> */}

                    <div className="campo-form-panther-joe mt-4 animation-icon-in-cinco">
                                <label htmlFor="referido" className="text-left" >Referido</label>

                                <input
                                    type="text"
                                    id="referido"
                                    name="referido"
                                    placeholder="ID de Referido (si aplica)"
                                    onChange={onChange}
                                    value={referido}
                                    />
                        </div>

                        

                    <div className="campo-form-panther-joe animation-icon-in-cinco">
                                <label htmlFor="name" className="text-left">Nombre y apellido</label>

                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Su nombre y apellido"
                                    onChange={onChange}
                                    value={name}
                                    autoComplete="off"
                                    required
                                    />
                        </div>


                        <div className="campo-form-panther-joe animation-icon-in-cinco">
                                <label htmlFor="email"className="text-left" >Correo electrónico </label>

                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Su correo electrónico"
                                    onChange={onChange}
                                    value={email}
                                    autoComplete="off"
                                    required
                                    />
                        </div>

                        <div className="campo-form-panther-joe animation-icon-in-cinco"> 

                                                        

                                                                    <label htmlFor="password" className="text-left" >Contraseña</label>
                                                                {mostrarpassword ?  
                                                                <div className="d-flex align-items-center pantherpassword"> 
                                                                

                                                                <input
                                                                    type="text"
                                                                    id="password"
                                                                    name="password"
                                                                    placeholder="Mi contraseña                 "
                                                                    onChange={onChange}
                                                                    value={password}
                                                                    // className="input-panther7 "
                                                                    
                                                                    required
                                                                    autocomplete="true"
                                                                    
                                                                    />
                                                                        
                                                                    <i  
                                                                        onClick={ () => ( 

                                                                            setMostrarPassword(false)

                                                                        )}
                                                                    
                                                                    
                                                                    >
                                                                        <VisibilityIcon className="icon-vis-style" />
                                                                        
                                                                    </i> 

                                                                </div> : 
                                                                
                                                                
                                                                <div className="d-flex align-items-center pantherpassword"> 
                                                                

                                                                <input
                                                                    type="password"
                                                                    id="password"
                                                                    name="password"
                                                                    placeholder="Mi contraseña                      "
                                                                    onChange={onChange}
                                                                    value={password}
                                                                    // className="campo-form-panther "
                                                                    required
                                                                    autocomplete="true"
                                                                    
                                                                    />

                                                                

                                                                    <i 
                                                                        onClick={ () => ( 

                                                                            setMostrarPassword(true)

                                                                        )}
                                                                    
                                                                    
                                                                    >
                                                                        <VisibilityOffIcon className="icon-vis-style"/>
                                                                    </i> 

                                                                </div> 
                                                                
                                                            } 





                                                        


                                                        </div>

                        <div className="campo-form-panther-joe animation-icon-in-cinco">
                                <label htmlFor="confirmar"className="text-left" >Confirmación de contraseña</label>

                                <input
                                    type="password"
                                    id="confirmar"
                                    name="confirmar"
                                    placeholder="Confirmar su contraseña"
                                    onChange={onChange}
                                    value={confirmar}
                                    autoComplete="off"
                                    required
                                    
                                    />
                        </div> 

                        <div className="campo-form-panther-joe mt-2">

                            <label className="text-left">Donde Escuchastes sobre Panther?</label>


                        <select className="control-joe  text-center " 
                                                                                          onChange={onChange}
                                                                                           value={origen} 
                                                                                          id="origen"
                                                                                          name="origen"
                                                                                          required
                                                                                          
                                                                                  
                                                                                  >
                                                                                  <option  value="" className="animation-cool" > - - - Haga Clic & Seleccione - - -</option>
                                                                                      <option value="Instagram"   >Instagram</option>
                                                                                      <option value="Facebook"   >Facebook</option>
                                                                                      <option value="Email"  >Email</option>
                                                                                      <option value="Youtube"  >Youtube</option>
                                                                                      <option value="Google"   >Google</option>
                                                                                      <option value="Recomendación"  >Recomendación</option>
                                                                                      <option value="Publicidad en Radio/TV"  >Publicidad en Radio/TV</option>
                                                                                      <option value="Un Amigo/a"  >Un Amigo/a</option>
                                                                                  </select>


                        </div>

                        

                      

                        
                                
                                
                                <div className={ `Checkpanther ${classes.root}`}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormGroup>
                                        
                                            <FormControlLabel
                                                control={<Checkbox checked={terminos} onChange={handleChange} name="terminos" required
                                                color="primary"
                                                 
                                                 />}
                                                label="Estoy de acuerdo con los términos y condiciones"
                                                className="checkbox-fixdos"
                                                onClick={ () => {
      
                                                    if (terminos === false) {
                                                        handleOpen()
                                                    }
                                                   
                                                  }}
                                            />
                                    
                                            </FormGroup>
                                            
                                        </FormControl>
                                </div>
                              

                        <div>
                        <button type="submit" className="button-jimf "  >
                                {elguardar}
                                </button>

                                <p className="text-dark-50 w-100 text-center">Su registro estará sujeto a un proceso de validación de datos y documentos</p>
                             
                                    
                        </div> 

                      

                            
                    
                        
                    </form>

                    <Link to={"/login"} className="enlace-cuenta">
                        <p>¿Ya tiene una cuenta <span>Panther7</span>? <span class="entrar">Entrar a su cuenta</span></p>
                    </Link>

            </div>

            {demo_status === "active" ? <p className="Hola">PLATAFORMA DEMO</p> :null }
                                                  
        </div> 

        <Modal
                    
                    className={classes.modal}
                    open={open} 
                    
                    disableScrollLock={true}
                    
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 900,
                    }}
                    >
                    <Fade in={open}
                    timeout={154}
                    onClose={handleClose}>
                        <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                        <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}> 

                        <CardHeader> 
                              
                              <h3 className="title display-4 mb-0 text-center">BIENVENIDO </h3>
                              <p className="text-center">¡Esta muy cerca de completar el registro de su cuenta!</p>
                              
                              <hr class="my-1 border-info"/> 
                              
                            </CardHeader>
                        
                        <div className="terminos-modal-fix">
                                            <Row className="d-flex justify-content-center">
                                                     
                                            </Row>
                                        </div> 
                                        <CardFooter>
                            <div className="row">
                                
                            
                                <div  className="col-12">
                                <button className=" btn mt-0 btn-block btn-primario btn-info btn-panther-power" onClick={handleClose}>
                                Continuar
                                </button>
                                </div>
                                    
                         
                            </div>
                        </CardFooter>
                                        
                                    
                        

                    </div>

                        </div>

                    </div>
                        </div>
                    </Fade>
                    </Modal> 


                     {mensaje ?  
                                 
                                    <ModalMensaje
                                                                
                                                                className={classes.modal}
                                                                open={openmensaje} 
                                                                mensaje={mensaje}
                                                                disableScrollLock={true}
                                                                props={props}
                                                                closeAfterTransition
                                                                BackdropComponent={Backdrop}
                                                                BackdropProps={{
                                                                    timeout: 900,
                                                                }}
                                                                >
                                                                <Fade in={openmensaje}
                                                                timeout={154}
                                                                onClose={handleCloseMensaje}>
                                                                    <div className="container">
                                                                <div className="row">
                                                                <div className="col-12 d-flex justify-content-center">
                                                                <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}> 
                                                                {/*                     
                                                                    <CardHeader> 
                                                                        
                                                                        <h3 className="title display-4 mb-0 text-center">BIENVENIDO </h3>
                                                                        <p className="text-center">¡Estas muy cerca de finalizar el registro de tu cuenta!</p>
                                                                        
                                                                        <hr class="my-1 border-info"/> 
                                                                        
                                                                        </CardHeader> */}
                                                                    
                                                                    <CardBody>
                                                                                        <Row className="d-flex justify-content-center">
                                                                                                <Col sm="12" md="11" className=" static-height"> 
                                                                                                
                                                                                                    
                                
                                                                                                    
                                                                                                    <hr className="bg-info"/>
                                
                                                                                                <h1 className="text-center">{mensaje}</h1>
                                                                    
                                                                                                </Col>       
                                                                                        </Row>
                                                                                    </CardBody> 
                                                                                    <CardFooter>
                                                                        <div className="row">
                                                                            
                                                                       
                                                                            <div  className="col-12">
                                                                            {mensaje === "Usuario creado con éxito. Confirme haciendo clic en el enlace que se le envió en su correo electrónico." ? 

                                                                                <button className="btn mt-0 btn-block btn-primario btn-info btn-panther-power" onClick={ e =>(
                                                                                    e.preventDefault(),
                                                                                     handleCloseMensaje(),
                                                                                     Limpiar(),
                                                                                     elGuardarCargando(false),
                                                                                     elGuardarCargando(false),
                                                                                     guardarRegistroMensaje(null),
                                                                                     LimpiarMensaje(), 
                                                                                    props.history.push('/login')
                                                                                )}>
                                                                                Iniciar sesión
                                                                                </button>

                                                                                :

                                                                                <button className="btn mt-0 btn-block btn-primario btn-info btn-panther-power" onClick={ ()=>(
                                                                                    handleCloseMensaje(),
                                                                                    Limpiar(),
                                                                                    elGuardarCargando(false),
                                                                                    elGuardarCargando(false),
                                                                                    guardarRegistroMensaje(null),
                                                                                    LimpiarMensaje()
                                                                                )}>
                                                                                Aceptar
                                                                                </button>





                                                                            }






                                                                            </div>
                                                                      
                                                                        </div>
                                                                    </CardFooter>
                                                                                    
                                                                                
                                                                    
                                
                                                                </div>
                                
                                                                    </div>
                                
                                                                </div>
                                                                    </div>
                                                                </Fade>
                                                                </ModalMensaje>            
                                : null 
                                
                                }

        </Fragment>
        </Fade>

     );
}
 
export default NuevaCuenta;
