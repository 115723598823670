


export const ES = 
    {  
      
    type:"ES",
    Login: {
      loading: "Cargando...",
      welcome: "Bienvenido",
      emailLabel: "Correo electrónico",
      emailPlaceholder: "Mi correo electrónico",
      passwordLabel: "Contraseña",
      passwordPlaceholder: "Mi contraseña",
      signInButton: "Iniciar sesión",
      forgotPasswordLink: "¿Olvidó su contraseña?",
      noAccountMessage: "¿Aún no tiene una cuenta?",
      createAccountLink: "Crear su cuenta ahora",
      welcomeMessage: "Nuestra plataforma de ofertas fue diseñada para todo tipo de inversor. Invierta diversificando sus riesgos de forma simple, accesible y sin inconvenientes, desde USD $50. Panther7 es una plataforma de vanguardia que proporciona acceso directo a oportunidades de inversiones seguras y respaldadas por activos especialmente seleccionados.",
      websiteLink: "www.groundscript.com",
      demoPlatform: "PLATAFORMA DEMO",
      confirmationEmailMessage: "El usuario debe confirmar la dirección de correo electrónico.",
      confirmationEmailTitle: "Felicidades, Ya falta poco para terminar tu registro",
      resendConfirmationLinkButton: "Reenviar enlace de confirmación de cuenta",
      tryAgainMessage: "Intente nuevamente.",
      tryAgainButton: "Intente nuevamente"
  },
  ProductosDeInversion: {
    camp01:'Fecha Fin:',
    camp02:'Descripción',
    camp03:'¡Invertir ahora!',
    camp04:'Clasificación:',
    camp05:'Participantes:',
    camp06:'Garantía:',
    camp07:'Tasa de Interés:',
    camp08:'Información adicional del proyecto',
    camp09:'Monto:',
    camp10:'Fecha de Rendimiento:',
    camp11:'Monto Invertido',
    camp12:'Tiempo Total de Contrato',
    camp13:'T. Restante de Contrato',
    camp14:'de participación de la oferta',
    camp15:'Productos de Inversión',
    camp16:'Escoge tu oferta preferida',
    camp17:'Siguiente',
    camp18:'Atrás',

  },
  
  SaldodelCliente: {
    camp01:'Saldo disponible',
    camp02:'Mis inversiones',
    camp03:'Ganancias al día',
    camp04:'Ganancias esperadas',
    camp05:'Mis ofertas',
    camp06:'Depósito pendiente',
    camp07:'Valor de la cuenta',
    camp08:'Saldo retenido',
  },
  UltimosMov: {
    camp01:'Últimos Movimientos',
    camp02:'movimientos en la cartera CapitalMarket hasta la fecha',
    camp03:'Exporte todos tus movimientos en un sólo Clic',
    camp04:'EXPORTAR MOVIMIENTOS ',
    camp05:'FECHA',
    camp06:'REFERENCIA',
    camp07:'DESCRIPCIÓN',
    camp08:'MONTO',
    camp09:'SALDO TOTAL',
    camp10:'Anterior',
    camp11:'Siguiente',
    camp12:'Página',
    camp13:'Sin Movimientos',
    camp14:'Aún no tiene movimientos registrados en CapitalMarket',
    camp15:'CapitalMarket | Movimientos registrados',
  },
  Graf: {
    camp01: 'Todas sus inversiones',
    camp02: 'Mis inversiones',
  },
  Nav: {
    camp01:'Inicio',
    camp02:'Proyectos',
    camp03:'Mis inversiones',
    camp04:'Mis ofertas',
    camp05:'Depósito',
    camp06:'Retiro',
    camp07:'Mi cuenta',
    camp08:'Inversiones',
    camp09:'Hacemos que el arte de invertir sea muy fácil y amigable. Sea cual sea su experiencia o nivel de inversor, con CapitalMarket estará respaldado.',
    camp10:'Continuar',
    camp11:' Para ver el estado de sus inversiones, dele Clic en continuar'

  }
  
}

export const EN = 
{  
   
        
    Header:{
        TituloH:'Evolve your relationship with wellness',
        ST1:'Balance',
        ST2:'Connection',
        ST3:'Energy',  
        DescripcionH:'Discover B.Caapi: a plant celebrated for centuries for its unique properties.',
        BottonH:'Buy',
        home:'Home',
        send:'Send',
    },       
    type:"EN",
    Login: {
      loading: "Loading...",
      welcome: "Welcome",
      emailLabel: "Email",
      emailPlaceholder: "My email",
      passwordLabel: "Password",
      passwordPlaceholder: "My password",
      signInButton: "Sign In",
      forgotPasswordLink: "Forgot your password?",
      noAccountMessage: "Don't have an account yet?",
      createAccountLink: "Create your account now",
      welcomeMessage: "Our investment platform was designed for all types of investors. Invest by diversifying your risks in a simple, accessible, and hassle-free way, starting from USD $50. Panther7 is a cutting-edge platform that provides direct access to secure investment opportunities backed by specially selected assets.",
      websiteLink: "www.panther7.com",
      demoPlatform: "DEMO PLATFORM",
      confirmationEmailMessage: "The user must confirm the email address.",
      confirmationEmailTitle: "Congratulations, You're almost done with your registration",
      resendConfirmationLinkButton: "Resend account confirmation link",
      tryAgainMessage: "Please try again.",
      tryAgainButton: "Try again"
  },
  ProductosDeInversion: {
    camp01: 'End Date:',
    camp02: 'Description',
    camp03: 'Invest Now!',
    camp04: 'Rating:',
    camp05: 'Participants:',
    camp06: 'Guarantee:',
    camp07: 'Interest Rate:',
    camp08: 'Additional Project Information',
    camp09: 'Amount:',
    camp10: 'Performance Date:',
    camp11: 'Invested Amount',
    camp12: 'Total Contract Time',
    camp13: 'Remaining Contract Time',
    camp14: 'of offer participation',
    camp15: 'Investment Products',
    camp16: 'Choose Your Preferred Offer',
    camp17: 'Next',
    camp18: 'Back',

},
SaldodelCliente: {
  camp01: 'Available Balance',
  camp02: 'My Investments',
  camp03: 'Daily Earnings',
  camp04: 'Expected Earnings',
  camp05: 'My Offers',
  camp06: 'Pending Deposit',
  camp07: 'Account Value',
  camp08: 'Reserved Balance',
},
UltimosMov: {
  camp01: 'Recent Transactions',
  camp02: 'transactions in the CapitalMarket portfolio to date',
  camp03: 'Export all your transactions in one click',
  camp04: 'EXPORT TRANSACTIONS',
  camp05: 'DATE',
  camp06: 'REFERENCE',
  camp07: 'DESCRIPTION',
  camp08: 'AMOUNT',
  camp09: 'TOTAL BALANCE',
  camp10: 'Previous',
  camp11: 'Next',
  camp12: 'Page',
  camp13: 'No Transactions',
  camp14: 'You do not have any transactions registered in CapitalMarket yet',
  camp15: 'CapitalMarket | Registered Transactions',
},
Graf: {
  camp01: 'All Your Investments',
  camp02: 'My Investments',
},
Nav: {
  camp01: 'Home',
  camp02: 'Projects',
  camp03: 'My Investments',
  camp04: 'My Offers',
  camp05: 'Deposit',
  camp06: 'Withdrawal',
  camp07: 'My Account',
  camp08: 'Investments',
camp09: 'We make the art of investing very easy and friendly. Whatever your experience or level of investor, with CapitalMarket, you will be supported.',
camp10: 'Continue',
camp11: 'To see the status of your investments, click Continue'

}






}


export const BR = 
{  
   
        
    Header:{
        TituloH:'Evolve your relationship with wellness',
        ST1:'Balance',
        ST2:'Connection',
        ST3:'Energy',  
        DescripcionH:'Discover B.Caapi: a plant celebrated for centuries for its unique properties.',
        BottonH:'Buy',
        home:'Home',
        send:'Send',
    },       
    type:"BR",
    Login: {
      loading: "Carregando...",
      welcome: "Bem-vindo",
      emailLabel: "E-mail",
      emailPlaceholder: "Meu e-mail",
      passwordLabel: "Senha",
      passwordPlaceholder: "Minha senha",
      signInButton: "Entrar",
      forgotPasswordLink: "Esqueceu sua senha?",
      noAccountMessage: "Ainda não tem uma conta?",
      createAccountLink: "Crie sua conta agora",
      welcomeMessage: "Nossa plataforma de investimento foi projetada para todos os tipos de investidores. Invista diversificando seus riscos de maneira simples, acessível e sem complicações, a partir de USD $50. A Panther7 é uma plataforma de ponta que oferece acesso direto a oportunidades de investimento seguras e respaldadas por ativos especialmente selecionados.",
      websiteLink: "www.groundscript.com",
      demoPlatform: "PLATAFORMA DEMO",
      confirmationEmailMessage: "O usuário deve confirmar o endereço de e-mail.",
      confirmationEmailTitle: "Parabéns, Você está quase concluindo seu registro",
      resendConfirmationLinkButton: "Reenviar link de confirmação da conta",
      tryAgainMessage: "Por favor, tente novamente.",
      tryAgainButton: "Tente novamente"
  },
  ProductosDeInversion: {
    camp01: 'Data de Término:',
    camp02: 'Descrição',
    camp03: 'Investir Agora!',
    camp04: 'Classificação:',
    camp05: 'Participantes:',
    camp06: 'Garantia:',
    camp07: 'Taxa de Juros:',
    camp08: 'Informações Adicionais do Projeto',
    camp09: 'Valor:',
    camp10: 'Data de Rendimento:',
    camp11: 'Valor Investido',
    camp12: 'Tempo Total do Contrato',
    camp13: 'Tempo Restante do Contrato',
    camp14: 'de participação na oferta',
    camp15: 'Produtos de Investimento',
    camp16: 'Escolha sua Oferta Preferida',
    camp17: 'Próximo',
    camp18: 'Voltar',
    
},
SaldodelCliente: {
  camp01: 'Saldo Disponível',
  camp02: 'Meus Investimentos',
  camp03: 'Ganhos Diários',
  camp04: 'Ganhos Esperados',
  camp05: 'Minhas Ofertas',
  camp06: 'Depósito Pendente',
  camp07: 'Valor da Conta',
  camp08: 'Saldo Retido',
},
UltimosMov: {
  camp01: 'Últimas Transações',
  camp02: 'transações na carteira do CapitalMarket até a data',
  camp03: 'Exporte todas as suas transações em um clique',
  camp04: 'EXPORTAR TRANSAÇÕES',
  camp05: 'DATA',
  camp06: 'REFERÊNCIA',
  camp07: 'DESCRIÇÃO',
  camp08: 'VALOR',
  camp09: 'SALDO TOTAL',
  camp10: 'Anterior',
  camp11: 'Próximo',
  camp12: 'Página',
  camp13: 'Sem Transações',
  camp14: 'Você ainda não possui transações registradas no CapitalMarket',
  camp15: 'CapitalMarket | Transações Registradas',
},
Graf: {
  camp01: 'Todos os Seus Investimentos',
  camp02: 'Meus Investimentos',
},
Nav: {
  camp01: 'Início',
  camp02: 'Projetos',
  camp03: 'Meus Investimentos',
  camp04: 'Minhas Ofertas',
  camp05: 'Depósito',
  camp06: 'Retirada',
  camp07: 'Minha Conta',
  camp08: 'Investimentos',
camp09: 'Tornamos a arte de investir muito fácil e amigável. Seja qual for a sua experiência ou nível de investidor, com a CapitalMarket, você estará respaldado.',
camp10: 'Continuar',
camp11: 'Para ver o estado dos seus investimentos, clique em Continuar'


}




 

}