import React, { useState , useEffect, useContext, Fragment } from "react";


import Dashboard from "views/Dashboard.js";
import Inversiones from "views/Inversiones.js";
import Ofertas from "views/Ofertas.js";
import Deposito from "views/Deposito.js";
import Retiro from "views/Retiro.js";
import Projectos from "views/Projectos";
import UserProfile from "views/UserProfile.js";  


import UltraDeposito from "views/DepositosView/Depositos.jsx";



import AuthContext from './context/autenticacion/authContext'

import  lang  from "context/autenticacion/authState";


var routes = [
  {
    path: "/dashboard",
    name: 'Início',
    
    icon: "tim-icons icon-bank",
    component: Dashboard,
    layout: "/cliente"
  },
  {
    path: "/projectos",
    name: "Projetos",
    
    icon: "tim-icons icon-spaceship",
    component: Projectos,
    layout: "/cliente"
  },
  {
    path: "/inversiones",
    name: "Investimentos",
    
    icon: "tim-icons icon-chart-bar-32",
    component: Inversiones,
    layout: "/cliente",
    
  },
  {
    path: "/ofertas",
    name: "Minhas Ofertas",
    
    icon: "tim-icons icon-chart-pie-36",
    component: Ofertas,
    layout: "/cliente"
  },
  // {
  //   path: "/deposito",
  //   name: "Depósito",
    
  //   icon: "tim-icons icon-coins",
  //   component: Deposito,
  //   layout: "/cliente"
  // },

  {
    path: "/ultradeposito",
    name: "Depósito",
    
    icon: "tim-icons icon-coins",
    component: UltraDeposito,
    layout: "/cliente"
  },
  {
    path: "/retiro",
    name: "Retirada",
    
    icon: "tim-icons icon-money-coins",
    component: Retiro,
    layout: "/cliente"
  },
  {
    path: "/user-profile",
    name: "Minha Conta",
    
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/cliente"
  },
  
 




]; 


var routespen = [

  {
    path: "/user-profile",
    name: "Mi Cuenta",
    
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/cliente"
  },
 




]; 











export default routes;
