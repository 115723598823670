import React, {useState, useEffect, Fragment, useContext} from 'react';
import clienteAxios from '../config/axios';
import Spinner from '../components/spinners/Spinner'


import {
    Card,
    CardHeader,
    CardTitle,
    Row,
    Col,
  } from "reactstrap";

  import AuthContext from '../context/autenticacion/authContext.js'

const SaldodelCliente = ({lang}) => { 


  const authContext = useContext(AuthContext);
  const { 
    saldos,
    CerrarSesion,
    Saldos
    
   } = authContext;  



  const [ cargando, guardarCargando] = useState(false);

    
  const [saldo, guardarSaldo] = useState({
    saldos:[
        {
            DISPONIBLE:'', 
            INVERTIDO:'', 
            GANANCIAS:'', 
            RETORNO:'', 
            RETIRO:'', 
            DEPOSITO:'', 
            OFERTA:'',
          }
    ]
  });



  useEffect( () => { 


    
    

    

    const consultarApiSaldo = async () => {
      
      guardarCargando(true); 
      // const{
      //   saldos:[
      //       {
      //           DISPONIBLE, 
      //           INVERTIDO, 
      //           GANANCIAS, 
      //           RETORNO, 
      //           RETIRO, 
      //           DEPOSITO,
      
      //           VALORCUENTA,
      //           GANANCIASESPERADA 
      //         }
      //   ]
      // } = saldo; 

      try {
        // const respuesta = await clienteAxios.post('/api/client/dashboard');

        ////con reducer
         // guardarSaldo(saldos)
        //  guardarSaldo(respuesta.data.data.saldos)

         guardarSaldo(saldos)
   
         // cambiar el estado de cargando
         guardarCargando(false);
        
       
              
       
        
    } catch (error) { 
        
       
        CerrarSesion()
        
    }

     
        

      // setTimeout( () => {
            
          
      //      // guardar cotizacion
      //      guardarSaldo(saldos)

      //      // cambiar el estado de cargando
      //      guardarCargando(false);
      //     },200);


      
      
    }

    consultarApiSaldo()

    

    
    

  },[]);


  const saldodisponible = ( cargando ) ? <Spinner/> :  
                                          
                                          <Fragment>
                                          <h5 className=" dash-h5">
                                            {/* Saldo disponible */}
                                            {lang.SaldodelCliente.camp01}
                                            </h5>
                                          
                                            <CardTitle tag="h3">
                                              { Object.keys(saldo).map((item, i) => (
                                                      <div key={i}  >
                                                          <i className="tim-icons icon-coins text-primary " /> 
                                                              {saldo[item].DISPONIBLE}
                                                              
                                                      </div>
                                              ))}

                                            {saldos.DISPONIBLE}

                                            </CardTitle> 
                                            </Fragment> 


  const montoinvertido = ( cargando ) ? <Spinner/> :  
                                          
                                        <Fragment>
                                            <h5 className=" dash-h5">
                                              {/* Mis inversiones */}
                                              {lang.SaldodelCliente.camp02}
                                              </h5>
                                            
                                            <CardTitle tag="h3">
                                            { Object.keys(saldo).map((item, i) => (
                                                      <div key={i} >
                                                          <i className="tim-icons icon-coins text-primary" /> 
                                                              {saldo[item].INVERTIDO}
                                                              
                                                      </div>
                                              ))}

                                              {saldos.INVERTIDO}
                                            </CardTitle>
                                        </Fragment> 


const gananciasdiarias = ( cargando ) ? <Spinner/> :  
                                          
                                        <Fragment>
                                            <h5 className=" dash-h5">
                                              {/* Ganancias al día  */}
                                              {lang.SaldodelCliente.camp03}
                                            </h5>
                                            
                                            <CardTitle tag="h3">
                                            { Object.keys(saldo).map((item, i) => (
                                                      <div key={i} >
                                                          <i className="tim-icons icon-coins text-primary" /> 
                                                              {saldo[item].GANANCIAS}
                                                              
                                                              
                                                      </div>
                                              ))}

                                                {saldos.GANANCIAS}
                                            </CardTitle>
                                        </Fragment> 




const gananciasesperadas = ( cargando ) ? <Spinner/> :  
                                          
                                        <Fragment>
                                            <h5 className=" dash-h5">
                                              {/* Ganancias esperadas */}
                                              {lang.SaldodelCliente.camp04}
                                            </h5>
                                            
                                            <CardTitle tag="h3">
                                            { Object.keys(saldo).map((item, i) => (
                                                      <div key={i} >
                                                          <i className="tim-icons icon-coins text-primary" /> 
                                                              {saldo[item].GANANCIASESPERADA}
                                                              
                                                      </div>
                                              ))}

                                                        {saldos.GANANCIASESPERADA}
                                            </CardTitle>
                                        </Fragment> 


const retirospendientes = ( cargando ) ? <Spinner/> :  
                                          
                                        <Fragment>
                                            <h5 className=" dash-h5">
                                              {/* Mis ofertas */}
                                              {lang.SaldodelCliente.camp05}
                                              </h5>
                                            
                                            <CardTitle tag="h3">
                                            { Object.keys(saldo).map((item, i) => (
                                                      <div key={i} >
                                                          <i className="tim-icons icon-coins text-primary" /> 
                                                              {saldo[item].OFERTA}
                                                              
                                                      </div>
                                              ))}

                                                        {saldos.OFERTA}
                                            </CardTitle>
                                        </Fragment> 




const depositospendientes = ( cargando ) ? <Spinner/> :  
                                        <Fragment>
                                        <h5 className=" dash-h5 ">
                                          {/* Depósito pendiente */}
                                          {lang.SaldodelCliente.camp06}
                                          </h5>
                                        
                                                          <CardTitle tag="h3">
                                                          { Object.keys(saldo).map((item, i) => (
                                                                    <div key={i} >
                                                                        <i className="tim-icons icon-coins text-primary" /> 
                                                                            {saldo[item].DEPOSITO}
                                                                            
                                                                    </div>
                                                            ))}

                                                              {saldos.DEPOSITO}
                                                          </CardTitle>
                                        </Fragment> 


const valordelacuenta = ( cargando ) ? <Spinner/> :  
                                        <Fragment>
                                        <h5 className=" dash-h5 ">
                                          {/* Valor de la cuenta */}
                                          {lang.SaldodelCliente.camp07}
                                          </h5>
                                        
                                                          <CardTitle tag="h3">
                                                          { Object.keys(saldo).map((item, i) => (
                                                                    <div key={i}  >
                                                                        <i className="tim-icons icon-coins text-primary" /> 
                                                                            {saldo[item].VALORCUENTA}
                                                                            
                                                                    </div>
                                                            ))}

                                                                {saldos.VALORCUENTA}
                                                          </CardTitle>
                                        </Fragment> 

const retencion = ( cargando ) ? <Spinner/> :  
                                          <Fragment>
                                          <h5 className=" dash-h5 ">
                                            {/* Saldo retenido  */}
                                            {lang.SaldodelCliente.camp08}
                                            </h5>
                                          
                                                            <CardTitle tag="h3">
                                                            { Object.keys(saldo).map((item, i) => (
                                                                      <div key={i} >
                                                                          <i className="tim-icons icon-coins text-primary" /> 
                                                                              {saldo[item].RETENCION}
                                                                              
                                                                      </div>
                                                              ))}
                                                              {saldos.RETENCION}
                                                            </CardTitle>
                                          </Fragment> 



                             

    return (  




        <>

      <div className="grid-dash"> 
              <div className="text-center dashstyle-box saldos-panther-final">
              {valordelacuenta}

              </div>
              <div className="text-center dashstyle-box saldos-panther-final">
              {saldodisponible}
                
              </div>
              <div className="text-center dashstyle-box">
              {gananciasdiarias}
              </div>
              <div className="text-center dashstyle-box">
                
                {gananciasesperadas} 
              </div>
              <div className="text-center dashstyle-box">
              {depositospendientes} 

              </div>
              <div className="text-center dashstyle-box">
              {montoinvertido}
                
              </div>
              <div className="text-center dashstyle-box">
              {retirospendientes}
              </div>
              <div className="text-center dashstyle-box">
                
              {retencion}
              </div>


      </div> 

      

        <Row className="d-none">

                    <Col md="3" lg="3">
                      <Card className="card-chart  saldos-panther-final  text-center">
                        <CardHeader className="height-fix-saldos">
                          {valordelacuenta}
                        </CardHeader>
                      
                      </Card>
                    </Col>

            <Col md="3" lg="3">
              <Card className="card-chart   saldos-panther-final  text-center">
                <CardHeader className="height-fix-saldos">
                  {saldodisponible}
                </CardHeader>
                
              </Card>
            </Col>

            <Col md="3" lg="3">
              <Card className="card-chart     text-center">
                <CardHeader className="height-fix-saldos">
                  {gananciasdiarias}
                </CardHeader>
               
              </Card>
            </Col>
                    <Col  md="3" lg="3">
                    <Card className="card-chart    text-center">
                    <CardHeader className="height-fix-saldos">
                      {gananciasesperadas} 
                    
                      </CardHeader>
                      
                      </Card>
                    </Col> 
            <Col  md="3" lg="3">
            <Card className="card-chart   text-center">
            <CardHeader className="height-fix-saldos">
            {depositospendientes} 
        
              </CardHeader>
              
              </Card>
            </Col>
                    <Col md="3" lg="3">
                      <Card className="card-chart   text-center">
                        <CardHeader className="height-fix-saldos">
                          {montoinvertido}
                        </CardHeader>
                  
                      </Card>
                    </Col>
            <Col md="3" lg="3">
              <Card className="card-chart  text-center">
                <CardHeader className="height-fix-saldos">
                 
                 {retirospendientes}
                </CardHeader>
                
              </Card>
            </Col>
                  <Col md="3" lg="3">
                    <Card className="card-chart text-center">
                      <CardHeader className="height-fix-saldos">
                      
                      {retencion}
                      </CardHeader>
                      
                    </Card>
                  </Col>

          </Row> 


          </>


    );
}
 
export default SaldodelCliente;