import React, {useContext, useState, useEffect} from 'react';
import AuthContext from '../../context/autenticacion/authContext.js'
import AudioLoading  from '../../assets/scss/black-dashboard-react/panther/sounds/loading.mp3'
import X from '../Logocapwhite.png'






const Loading = () => {

   

    

    const Playit = () => {
        var audio = new Audio(AudioLoading);
        audio.play();
    }

    
   

    //    useEffect(() => {
        

    //      Playit()

        
    //    }, []);



    useEffect(() => {
    window.scrollTo(0, 0)
  }, [panther_load])


    const authContext = useContext(AuthContext);

    const { lang, PantherNoLoad, PantherLoad, panther_load , recargandoapp,  } = authContext;  
   
        
    
    return ( 

        <>

            


       { panther_load === true ? 

                <>

                

                


                <div className={`loading ${recargandoapp === true ? 'loading-fx-null': ''} `}>

                {/* <div className="box-content-fx-fit-load waveform-x4">
                <ParticleBg/>

                </div>   */}
                        

                <div className="elemento1">
                    <img alt="..." src={X} />
                    <img className="img-flat" alt="..." src={X} />
                    
                </div>




                <div className="elemento2">
                <div className="bar">

                    </div>

                    

                    

                <p>{lang.Login.loading}</p>

                </div>

                {/* <div className="bg-particle-media-load ">
                    <ParticlesBackground/>
                </div>   */}








                </div>

                </>
                
                
                : null


       }


        </>


     );
}
 
export default Loading;